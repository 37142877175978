const initialState = {
  customerTableLoading: false,
  customerSidebarLoading: false,
  data: [],
  params: null,
  allData: [],
  allUtility: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  refresh: 0
}

const CustomerDataListReducer = (state = initialState, action) => {
  const { type, data, payload } = action

  switch (type) {
    case "SET_CUSTOMER_TABLE_LOADING":
      return {
        ...state,
        customerTableLoading: payload
      }
    case "SET_CUSTOMER_SIDEBAR_LOADING":
      return {
        ...state,
        customerSidebarLoading: payload
      }
    case "GET_CUSTOMER_DATA":
      return {
        ...state,
        data: data,
        // totalPages: action.totalPages,
        params: action.params
      }
    case "GET_ALL_CUSTOMER_DATA":
      return {
        ...state,
        allData: data,
        totalRecords: data.length
      }
    case "FILTER_CUSTOMER_DATA":
      let value = action.value
      let filteredData = []
      if (value.length) {
        filteredData = state.allData
          .filter(
            item => item.name.toLowerCase().includes(value.toLowerCase()) ||
              item.reference.toLowerCase().includes(value.toLowerCase())
          )
        return { ...state, filteredData }
      } else {
        filteredData = state.data
        return { ...state, filteredData }
      }
    case "ADD_CUSTOMER_DATA":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === payload.id) {
            return payload
          }
          return item
        })
      }
    case "UPDATE_CUSTOMER_DATA":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === payload.id) {
            return payload
          }
          return item
        })
      }
    case "GET_UTILITY_LIST":
      return {
        ...state,
        allUtility: (data).sort((a, b) => a.name.localeCompare(b.name, undefined, { caseFirst: "upper" }))
      }
    default:
      return state
  }
}

export default CustomerDataListReducer
