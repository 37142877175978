import Axios from "axios";
import cloneDeep from 'lodash/cloneDeep';

import { getUrl } from "./Config";
import { store } from "../../redux/storeConfig/store";
import { getDecryptedItem } from "../encryptedStorage"

const CONFIG = getUrl();
const CONFIG_URL = CONFIG.URL

///////////////////////////////////
const getHeaderData = (customOptionsObj = {}) => {
  const customOptions = customOptionsObj || {}

  if (getDecryptedItem("orgName")) {
    const userData = JSON.parse(getDecryptedItem("data"));
    const userInfo = userData?.userInfo
    const headers = {}

    if (userInfo) {
      const { username, customerId, name, id: userId } = userInfo
      if (username) {
        headers.user_email = username || ""
      }

      if (customerId) {
        headers.user_org = customerId
        headers.organisationId = customerId
      } else if (getDecryptedItem("loggedInUserRole") === "Customer") {
        headers.user_org = userId || ""
        headers.organisationId = userId || ""
      } else if (getDecryptedItem("loggedInUserRole") === "Partner") {
        headers.user_org = ""
        headers.organisationId = ""
      } else if (getDecryptedItem("loggedInUserRole") === "Super Admin") {
        headers.organisationId = ""
        headers.user_org = ""
      } else {
        headers.organisationId = ""
        headers.user_org = ""
      }

      headers.username = name || ""
    }

    const token = getDecryptedItem("token");
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    return {
      ...customOptions,
      headers: {
        ...headers,
        ...cloneDeep(customOptions.headers || {})
      }
    }
  }

  return {
    ...customOptions,
    headers: {
      ...cloneDeep(customOptions.headers || {})
    }
  };
}

function get(url, customOptionsObj) {
  if (!url) {
    console.error("URL is a mandatory field to make an API call");
    return;
  }

  const head_data = getHeaderData(customOptionsObj);

  return new Promise((resolve, reject) => {
    Axios.get(url, head_data).then((data) => {
      resolve(data);
    }, (e) => {
      reject(e);
    });
  });
}

function post(url, param, customOptionsObj) {
  if (!url) {
    console.error("URL is a mandatory field to make an API call");
    return;
  }

  const head_data = getHeaderData(customOptionsObj);

  return new Promise((resolve, reject) => {
    Axios.post(url, param, head_data).then((resp) => {
      resolve(resp);
    }, (e) => {
      reject(e);
    });
  });

}

function put(url, param) {
  if (!url) {
    console.error("URL is a mandatory field to make an API call");
    return;
  }

  const head_data = getHeaderData();

  return new Promise((resolve, reject) => {
    Axios.put(url, param, head_data).then((resp) => {
      resolve(resp);
    }, (e) => {
      reject(e);
    });
  });
}

function deleteApi(url, param, customOptionsObj) {
  if (!url) {
    console.error("URL is a mandatory field to make an API call");
    return;
  }

  const head_data = getHeaderData(customOptionsObj);

  return new Promise((resolve, reject) => {
    Axios.delete(url, { ...head_data, data: param }).then((resp) => {
      resolve(resp);
    }, (e) => {
      reject(e);
    });
  });
}
///////////////////////////////////

const getUserInfoFromAuth0Token = (token) => {
  const url = `${CONFIG_URL.USER_INFO.GET}`;

  return new Promise((res, rej) => {
    get(url, { headers: { token } }).then(
      (resp) => {
        const { data } = resp;
        res(data);
      },
      (e) => {
        rej(e);
      }
    );
  });
};

function logOut() {
  store.dispatch({ type: "USER_LOGOUT" })
  sessionStorage.clear();
  localStorage.clear();

  window.location.href = "/login"
}

const forceLogOut = () => {
  logOut()
  setTimeout(() => {
    window.location.reload(true)
  }, 500)
}

export {
  get,
  post,
  put,
  deleteApi,
  getUserInfoFromAuth0Token,
  logOut, forceLogOut
};
