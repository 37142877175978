import cloneDeep from "lodash/cloneDeep"

const initialState = {
  utilityTableLoading: false,
  utilitySidebarLoading: false,
  data: [],
  params: null,
  allData: [],
  allConnectors: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  refresh: 0,
  modalData: {
    emableModel: false,
    message: "",
    displayMsg: "",
    enableCancelButton: true,
    enableOkButton: true,
    headerMsg: ""
  }
  // assetNameRes: []
}

const UtilityDataListReducer = (state = initialState, action) => {
  const { type, data, payload } = action

  switch (type) {
    case "SET_UTILITY_TABLE_LOADING":
      return {
        ...state,
        utilityTableLoading: payload
      }
    case "SET_UTILITY_SIDEBAR_LOADING":
      return {
        ...state,
        utilitySidebarLoading: payload
      }
    case "GET_UTILITY_DATA":
      return {
        ...state,
        data: data,
        // totalPages: action.totalPages,
        params: action.params
      }
    case "OPEN_MODEL":
      return {
        ...state,
        modalData: action.value,
      }
    case "GET_ALL_UTILITY_DATA":
      return {
        ...state,
        allData: data,
        totalRecords: data.length
      }
    case "FILTER_UTILITY_DATA":
      let value = action.value
      let filteredData = []
      if (value.length) {
        filteredData = state.allData
          .filter(
            item => item.name.toLowerCase().includes(value.toLowerCase()) ||
              item.description.toLowerCase().includes(value.toLowerCase())
          )
        return { ...state, filteredData }
      } else {
        filteredData = state.data
        return { ...state, filteredData }
      }
    case "ADD_UTILITY_DATA":
      return {
        ...state,
        allData: state.allData.concat(payload),
        totalRecords: state.allData.length
      }
    case "UPDATE_UTILITY_DATA":
      return {
        ...state,
        allData: state.allData.map((item) => {
          if (item.id === payload.id) {
            return payload
          }
          return item
        })
      }
    case "DELETE_UTILITY_DATA":
      let index = state.data.findIndex(item => item.id === action.obj.id)
      const updatedData = cloneDeep(state.allData)
      updatedData.splice(index, 1)
      return {
        ...state,
        allData: updatedData,
        totalRecords: state.allData.length
      }
    case "GET_CONNECTOR_DATA":
      return {
        ...state,
        allConnectors: data
      }
    case "ASSET_NAME_CHECK":
      return {
        ...state,
        assetNameRes: data
      }
    default:
      return state
  }
}

export default UtilityDataListReducer
