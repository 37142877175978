
function getServerType() {
  const { hostname } = window.location
  switch (true) {
    case hostname === "test.billingmanager.cloudhub360.com":
      return "test"
    case hostname === "demo.billingmanager.cloudhub360.com":
      return "demo"
    case hostname === "home.billingmanager.cloudhub360.com":
      return "prod"
    case hostname.includes("localhost"):
      return "localhost"
    default:
      return "demo"
  }
}

function getUrl() {
  let AUTH0_BASE_URL

  const server = getServerType()

  switch (server) {
    case "test":
      AUTH0_BASE_URL = "https://test.um.cloudhub360.com"
      break

    case "demo":
      AUTH0_BASE_URL = "https://demo.um.cloudhub360.com"
      break

    case "prod":
      AUTH0_BASE_URL = "https://demo.um.cloudhub360.com"
      break

    case "localhost":
      AUTH0_BASE_URL = "https://test.um.cloudhub360.com"
      break

    default:
      AUTH0_BASE_URL = "https://test.um.cloudhub360.com"
      break
  }

  return {
    URL: {
      LIST_CUSTOMER: {
        GET_ALL: AUTH0_BASE_URL + '/api/customerbm/getAll',
        GET_BY_ID: AUTH0_BASE_URL + '/api/customerbm/getById',
      },
      UPDATE_CUSTOMER: {
        POST: AUTH0_BASE_URL + '/api/customerbm/update',
      },
      ADD_CUSTOMER: {
        POST: AUTH0_BASE_URL + '/api/customerbm/create',
      },
      LIST_CONNECTOR: {
        GET: AUTH0_BASE_URL + '/api/connector/listAll',
      },
      DELETE_CONNECTOR: {
        GET: AUTH0_BASE_URL + '/api/connector/delete',
      },
      UPDATE_CONNECTOR: {
        POST: AUTH0_BASE_URL + '/api/connector/update',
      },
      ADD_CONNECTOR: {
        POST: AUTH0_BASE_URL + '/api/connector/add',
      },
      CONNECTOR_DATABASE: {
        POST: AUTH0_BASE_URL + '/api/connector/db/connect',
      },
      CONNECTOR_TABLE: {
        POST: AUTH0_BASE_URL + '/api/connector/get/tables',
      },
      GET_UTILITY_DATA: {
        GET: AUTH0_BASE_URL + '/api/utility/getList',
      },
      ADD_UTILITY: {
        POST: AUTH0_BASE_URL + '/api/utility/create',
      },
      UPDATE_UTILITY: {
        POST: AUTH0_BASE_URL + '/api/utility/update',
      },
      DELETE_UTILITY: {
        GET: AUTH0_BASE_URL + '/api/utility/delete',
      },
      LOGIN: {
        POST: `${AUTH0_BASE_URL}/api/user/login`
      },
      USER_INFO: {
        GET: `${AUTH0_BASE_URL}/api/token/userInfo`
      },
      CUSTOMER: {
        GET: `${AUTH0_BASE_URL}/api/customer`
      },
      DASHBOARD: {
        key: "dashboard",
        TODAY_BAR_CHART: AUTH0_BASE_URL + '/api/dashboard/dailyCost',
        MONTHLY_BAR_CHART: AUTH0_BASE_URL + '/api/dashboard/monthlyCost',
        YEARLY_BAR_CHART: AUTH0_BASE_URL + '/api/dashboard/yearlyCost',
        PREDICT_FUTURE_COST: AUTH0_BASE_URL + '/api/dashboard/yearlyCost',
        EXPECTED_UNIT_USAGE: AUTH0_BASE_URL + '/api/dashboard/$type/expectedUnitUsage',
        PREDICT_FUTURE_PAGE_PROCESSED: AUTH0_BASE_URL + '/api/dashboard/workbenchUsage',
        ASK_ELIE_PER_MONTH: AUTH0_BASE_URL + '/api/dashboard/askElieUsage',
        SPEND_BY_CUSTOMER: AUTH0_BASE_URL + '/api/dashboard/monthlySpendByDepartment',
        MONTHLY_SPEND_USER: AUTH0_BASE_URL + '/api/dashboard/monthlySpendByUsers',
        MONTHLY_SPEND_STATE: AUTH0_BASE_URL + '/api/dashboard/monthlySpentState',
        BILLING_INVOICE_ARCHIVE: AUTH0_BASE_URL + '/api/dashboard/billingInvoiceArchive',
        GENERATE_CUSTOMER_BILL_PDF: AUTH0_BASE_URL + '/api/dashboard/generate/Pdf'
      }
    }
  }
}

export {
  getUrl
}
