const initialState = {
  connectorTableLoading: false,
  connectorSidebarLoading: false,
  data: [],
  params: null,
  allData: [],
  totalPages: 0,
  filteredData: [],
  totalRecords: 0,
  cloudTable: [],
  refresh: 0,
  modalData: {
    emableModel: false,
    message: "",
    displayMsg: "",
    enableCancelButton: true,
    enableOkButton: true,
    headerMsg: ""
  }
}

// emableModel

const DataListReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case "SET_CONNECTOR_TABLE_LOADING":
      return {
        ...state,
        connectorTableLoading: payload
      }
    case "SET_CONNECTOR_SIDEBAR_LOADING":
      return {
        ...state,
        connectorSidebarLoading: payload
      }
    case "GET_DATA":
      return {
        ...state,
        data: action.data,
        //   totalPages: action.totalPages,
        params: action.params
      }
    case "GET_ALL_DATA":
      return {
        ...state,
        allData: action.data,
        totalRecords: action.data.length
      }
    case "OPEN_MODEL":
      return {
        ...state,
        modalData: action.value,
      }
    case "FILTER_DATA":
      let value = action.value
      let filteredData = []
      if (value.length) {
        filteredData = state.allData
          .filter(item => item.name.toLowerCase().includes(value.toLowerCase()) ||
            item.description.toLowerCase().includes(value.toLowerCase()))

        if (state.params && state.params.page) {
          filteredData = filteredData.slice(state.params.page - 1, state.params.perPage)
        }

        return { ...state, filteredData }
      } else {
        filteredData = state.data
        return { ...state, filteredData }
      }
    case "ADD_CONNECTOR":
      return {
        ...state,
        data: state.data.concat(payload),
        totalRecords: state.allData.length
      }
    case "UPDATE_CONNECTOR":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === payload.id) {
            return payload
          }
          return item
        })
      }
    case "DELETE_DATA":
      let index = state.data.findIndex(item => item.id === action.obj.id)
      let updatedData = [...state.data]
      updatedData.splice(index, 1)
      return {
        ...state,
        data: updatedData,
        totalRecords: state.allData.length
      }

    default:
      return state
  }
}

export default DataListReducer
