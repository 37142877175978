import { combineReducers } from "redux"
// import calenderReducer from "./calendar/"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import dataList from "./connector/"
import utilityDataListReducer from "./Utility/"
import customerDataListReducer from "./customer/"

const appReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dataList: dataList,
  utilityDataListReducer: utilityDataListReducer,
  customerDataListReducer: customerDataListReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default rootReducer
