import React from "react"
// import workbench from './../assets/icons/workbench-m.png'
// import askelie from './../assets/icons/elie.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faTh, faUsers, faPlug } from '@fortawesome/free-solid-svg-icons'

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <FontAwesomeIcon icon={faHome} className='menu-icon' />,
    permissions: ["admin", "editor"],
    navLink: "/admin-dashboard"
  },
  {
    id: "Connector",
    title: "Connector",
    type: "item",
    icon: <FontAwesomeIcon icon={faPlug} className='menu-icon' />,
    permissions: ["admin", "editor"],
    navLink: "/connector"
  },
  {
    id: "utility",
    title: "Utility",
    type: "item",
    icon: <FontAwesomeIcon icon={faTh} className='menu-icon' />,
    permissions: ["admin", "editor"],
    navLink: "/utility"
  },
  {
    id: "customer",
    title: "Customer",
    type: "item",
    icon: <FontAwesomeIcon icon={faUsers} className='menu-icon' />,
    permissions: ["admin", "editor"],
    navLink: "/customer"
  },
  {
    id: "",
    groupTitle: "",
    type: "groupHeader",
  },
  {
    id: "",
    groupTitle: "",
    type: "groupHeader",
  },
]

export default navigationConfig



